import { getApplicantReturnTokenUsingPhoneNumberAndDOB } from '@/services/homeApi'

const PHONE_NUMBER_LENGTH = 10

const validateDOBParam = (dateOfBirth: string): void => {
    if (typeof dateOfBirth !== 'string' || dateOfBirth.length === 0) {
        throw new TypeError(`Invalid dateOfBirth. dateOfBirth must be of type string and not empty`)
    }
}

const validatePhoneNumberParam = (phoneNumber: string): void => {
    if (typeof phoneNumber !== 'string' || phoneNumber.length !== PHONE_NUMBER_LENGTH || /^[09]{10}$/.test(phoneNumber)) {
        throw TypeError(`Invalid phoneNumber. phoneNumber must be of type string. ${PHONE_NUMBER_LENGTH} chars in length and only contain numbers`)
    }
}

export const getApplicantReturnToken2UsingPhoneNumberAndDOB = async (phoneNumber: string, dob: string): Promise<string | null> => {
    validatePhoneNumberParam(phoneNumber)
    validateDOBParam(dob)

    const response = await getApplicantReturnTokenUsingPhoneNumberAndDOB(phoneNumber, dob)

    const responsePayload = response?.data?.payload

    return responsePayload?.returnToken2 || null
}
